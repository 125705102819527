.header-color {
  background: #241520; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #536976, #203a43, #536976); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #536976, #203a43, #536976); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.landing-grid {
  background: #536976; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #536976, #203a43, #536976); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #536976, #203a43, #536976); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  text-align: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.projects-grid {
  display: flex;
}

/* Resume Page css */
.resume-right-col {
  background: #203a43;
  color: white;
  padding: 2em;
  text-align: left;
}

.resume-content {
  justify-content: center;
  text-align: center;
}
/* contact page css */

.contact-body {
  margin: auto;
  width: 100%;
  justify-content: center;
  padding-top: 2em;
}

.contact-grid {
  text-align: center;
  justify-content: center;
  background: white;
  color: black;
  margin-bottom: 20px;
}

.contact-grid h3 {
  font-family: 'Anton', sans-serif;
}

.contact-grid p {
  font-family: 'Oxygen', sans-serif;
}


.contact-grid hr {
  border-top: 5px dotted black;
  width: 50%;
  margin: auto;
}

.contact-list i {
  font-size: 66px;
  padding-right: 5rem;
}

.aboutme-body {
  margin: auto;
  width: 100%;
  justify-content: center;
  padding-top: 2em;
}

.aboutme-grid{
  text-align: center;
  justify-content: center;
  width: 80%;
  background: white;
  color: black;
  margin-bottom: 20px;
}

.aboutme-grid hr {
  border-top: 5px dotted black;
  width: 50%;
  margin: auto;
}
/* contact page css */

.avatar-img {
  height: 250px;
  padding-top: 5em;

}

.aboutme-text{
  background: white;
  transition-duration: 1s;
}
.aboutme-text p {
  width: 75%;
  margin: auto;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  text-align: justify;
  font-size: 20px;
  font-family: Garamond, serif;
}
.aboutme-text-content{
  width: 75%;
  margin: auto;
  padding-top: 1em;
  padding-bottom: 0.5em;
  text-align: center;
  font-size: 20px;
  font-family: Garamond, serif;
}

.aboutme-text:hover {
  background: #536976; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #536976, #203a43, #536976); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #536976, #203a43, #536976); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  border-radius: 50% 20% / 10% 40%;
  color: aliceblue;
}
.aboutme-text:hover {
  transition: 1s;
  box-shadow: 10px 10px 0 #FBC638;
}

.cv-grid{
  text-align: center;
  justify-content: center;
  width: 100%;
  background: white;
  color: black;
  margin-bottom: 20px;
}
.banner-text {
  background-color: rgb(39, 38, 38);
  opacity: .8;
  width: 75%;
  margin: auto;
  border-radius: 10px;
}

.banner-text h3 {
  font-size: 35px;
  font-weight: bold;
  color: white;
  padding-top: 0.3em;
}

.banner-text hr {
  border-top: 5px dotted white;
  width: 50%;
  margin: auto;
}

.banner-text p {
  color: white;
  font-size: 20px;
  padding: 1em;
}

.social-links {
  display: flex;
  justify-content: space-between;
  width: 50%;
  margin: auto;
}

.social-links i {
  color: white;
  font-size: 3em;
  padding: 8px;
}

.footer-color{
  background: #536976; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #536976, #203a43, #536976); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #536976, #203a43, #536976); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.drawer-color {
  background: #bdc3c7; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #bdc3c7, #2c3e50); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #bdc3c7, #2c3e50); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}